<template>
  <section class="top-banner">
    <div class="top-banner__text">
      <HomeTopBannerText />
      <CommonFindBestTutorsBtn
        class="mt-6 lg:mt-0"
        name="top-banner"
        classes="lg:w-[297px] w-full"
      />
    </div>
    <HomeTopBannerImg class="order-1 lg:order-2" />
  </section>
</template>
<script>
import HomeTopBannerText from './top-banner-text.vue'
import HomeTopBannerImg from './top-banner-img.vue'
import CommonFindBestTutorsBtn from '@/components/common/find-best-tutors/find-best-tutors-btn.vue'

export default {
  name: 'HomeTopBanner',
  components: { CommonFindBestTutorsBtn, HomeTopBannerText, HomeTopBannerImg }
}
</script>

<style scoped lang="scss">
.top-banner {
  @apply container flex justify-between flex-col lg:flex-row;
  &__text {
    @apply flex flex-col justify-between order-2 lg:order-1 mt-6 lg:mt-0;
  }
}
</style>
