<template>
  <div class="base-badge-groups">
    <BaseBadge
      v-for="(item,key) in list"
      :key="key"
      :text="item[label]"
      :to="link(item)"
      tag="a"
      :rel="!hasRelTag ? null : (item.follow ? 'follow' : 'nofollow')"
    />
  </div>
</template>

<script>
export default {
  name: 'BaseBadgeGroup',
  props: {
    label: {
      type: String,
      default: 'name'
    },
    list: {
      type: Array,
      default: () => []
    },
    hasRelTag: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    link (item) {
      return item?.link?.link
    }
  }
}
</script>

<style scoped lang="scss">
.base-badge-groups {
  @apply flex items-center flex-nowrap lg:flex-wrap overflow-x-auto w-full;
  > a.base-badge {
    @apply lg:mb-7 mr-2 ;
  }
}
</style>
