<template>
  <section>
    <div class="container">
      <BaseBadgeGroup :list="getInternalLinks" label="anchor" :has-rel-tag="true" class="hide-scroll" />
    </div>
  </section>
</template>
<script>
import BaseBadgeGroup from '@design-system/base/badge/badge-group'
import { mapGetters } from 'vuex'

export default {
  name: 'SectionSeoContentInternalLinks',
  components: {
    BaseBadgeGroup
  },
  computed: {
    ...mapGetters('global', ['getInternalLinks'])
  }
}
</script>

<style lang="scss" scoped>
.hide-scroll {
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
}
</style>
