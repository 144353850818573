<template>
  <ul
    class="divide-y divide-primary-50"
    :class="classes"
  >
    <li
      v-for="item in 8"
      :key="item"
      class="animate-pulse h-2 my-4 py-4"
    >
      <div class="rounded bg-primary-50 h-2 w-1/4" />
    </li>
  </ul>
</template>

<script>
export default {
  name: 'SearchCourseSkeleton',
  props: {
    classes: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>

</style>
