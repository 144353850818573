
export const renderHead = ({ meta, scripts, data = {}, image = '/front-img/icon.png' }) => {
  const title = meta.title
  const metas = [
    {
      hid: 'keywords',
      name: 'keywords',
      content: meta.keywords
    },
    {
      hid: 'description',
      name: 'description',
      content: meta.description
    },
    {
      hid: 'og:title',
      property: 'og:title',
      content: meta.title
    },
    {
      hid: 'og:description',
      property: 'og:description',
      content: meta.description
    },
    {
      hid: 'og:url',
      property: 'og:url',
      content: meta.canonical
    },
    {
      hid: 'og:image',
      property: 'og:image',
      content: image
    }

  ]
  let link = [
    {
      hid: 'canonical',
      rel: 'canonical',
      href: meta.canonical
    },
    {
      hid: 'image',
      rel: 'preload',
      as: 'image',
      href: image
    }
  ]
  if (meta?.robots) {
    metas.push({
      hid: 'robots',
      name: 'ROBOTS',
      content: meta.robots
    })
  }
  if (data.link && data.link.length > 0) {
    link = [...link, ...data.link]
  }
  if (meta.hreflang) {
    const out = []
    for (const x in meta.hreflang) {
      out.push({
        rel: 'alternate', href: meta.hreflang[x].link, hreflang: meta.hreflang[x].hreflang
      })
    }
    link = [...link, ...out]
  }
  return {
    title,
    meta: metas,
    link
  }
}
