<script>
export default {
  name: 'TopBannerImg'
}
</script>

<template>
  <BaseImg
    class="mx-auto lg:mx-0"
    :src="[require('@images/public/home/banner/home-top-banner-mobile.webp'),
           require('@images/public/home/banner/home-top-banner.webp')]"
    :sizes="{lg:['750',295], xs: ['328','185']}"
    preload
  />
</template>

<style scoped lang="scss">

</style>
