<template>
  <div>
    <SectionSearchCourse :emit-response="true" @search="searchCourse" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SectionSearchCourse from '@app/design-system/section/search-course/search-course.vue'
import { ArrayToParams } from '@app/helper/utils'

export default {
  name: 'CommonFindBestTutorsAction',
  components: { SectionSearchCourse },
  props: {
    classes: {
      type: String,
      default: 'lg:w-[245px] w-full'
    },
    name: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters('dialog', ['allDialogs', 'getDialog']),
    ...mapGetters('local', ['getListParams', 'getListQueries'])
  },
  methods: {
    ...mapActions('dialog', ['showDialog', 'hideDialog']),
    ...mapActions('local', ['updateListParams', 'updateListQueries']),
    async searchCourse (course) {
      const p = ArrayToParams(course?.link.parameters)
      await this.updateListParams(p)
      await this.updateListQueries({ page_number: null, teacher_gender: null, ignore_skip: null })
      this.hideDialog()
      await this.updatedData()
    },
    updatedData () {
      const url = this.localePath({
        name: this.$routes.tutor_list.name,
        params: this.getListParams,
        query: this.getListQueries
      })
      this.$router.replace(url)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
