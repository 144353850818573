<template>
  <div>
    <BaseButton
      :label="$t('actions.find_best_tutor')"
      :class="classes"
      icon-left="search"
      light
      :size="size"
      @click="findClicked"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CommonFindBestTutorsBtn',
  props: {
    classes: {
      type: String,
      default: 'lg:w-[245px] w-full'
    },
    name: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'md-lg'
    }
  },
  computed: {
    ...mapGetters('dialog', ['allDialogs', 'getDialog'])
  },
  methods: {
    ...mapActions('dialog', ['showDialog']),
    findClicked () {
      this.showDialog(this.allDialogs.SEARCH_COURSE)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
