<template>
  <BaseDialog
    :ref="name"
    width="md"
    height="fixed"
    mobile-height="full"
    :name="name"
    class="search-course"
    :title-header="$t('common.titles.search_course_dialog')"
  >
    <template #header-custom>
      <!--      <div class="mt-7">
        <BaseSearchField
          :placeholder="$t('placeholders.search_courses')"
          input-class="w-full"
          @typingFinished="searchTerm"
        />
      </div>-->
      <div class="mt-6 wd-full">
        <p v-if="showBreadcrumb" class="search-course__breadcrumb">
          <span @click="backToParent">
            <BaseIcon name="arrow_back" color="primary" />
            <span>{{ getParent.name }}</span>
          </span>
        </p>
      </div>
    </template>
    <template #body>
      <ul v-if="!getLoadingList" class="search-course__list">
        <li
          v-for="course in getCourses"
          :key="course.id"
          class="search-course__item"
          @click="updateCourse(course)"
        >
          <span>{{ course.name }}</span>
          <BaseIcon
            v-if="course.type !=='COURSE'"
            name="keyboard_arrow_right"
            color="primary"
          />
        </li>
      </ul>
      <SearchCourseSkeleton v-else />
    </template>
  </BaseDialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SearchCourseSkeleton from './search-course-skeleton'

export default {
  name: 'SectionSearchCourse',
  components: { SearchCourseSkeleton },
  props: {
    emitResponse: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      search: ''
    }
  },
  computed: {
    ...mapGetters('dialog', ['allDialogs']),
    ...mapGetters('course', ['getCourses', 'getLoadingList', 'getParent', 'getTypeSelected']),
    name () {
      return this.allDialogs?.SEARCH_COURSE
    },
    showBreadcrumb () {
      return this.getParent && this.getParent.name
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.searchCoursesList()
    })
  },
  methods: {
    ...mapActions('course', ['searchCoursesList', 'updateType', 'updateCourseId', 'updateSearchTerm']),
    updateCourse (course) {
      if (course.type === 'COURSE') {
        this.goToListPage(course)
        return
      }
      this.updateType('CHILDREN')
      this.updateCourseId(course.id)
      this.searchCoursesList()
    },
    backToParent () {
      if (this.getTypeSelected === 'CHILDREN') {
        this.updateType('SIBLINGS')
      } else {
        this.updateCourseId(this.getParent.id)
      }
      this.searchCoursesList()
    },
    searchTerm (term) {
      this.updateSearchTerm(term)
      this.updateType('CHILDREN')
      this.searchCoursesList()
    },
    goToListPage (course) {
      if (this.emitResponse) {
        this.$emit('search', course)
        return
      }
      const path = this.$links.tutor_list_params(this.$nuxt, course)
      this.hide()
      setTimeout(() => {
        this.$router.push({ path })
      }, 1000)
    },
    hide () {
      this.$refs[this.name].hide()
    }
  }
}
</script>

<style scoped lang="scss">
.search-course {
  &__placeholder {
    @apply pb-3 text__body-3-md--regular text-body-2 border-b border-border-cart w-full;
  }
  &__list {
    @apply divide-y divide-border-cart;
  }
  &__item {
    @apply py-4 text__body-2-md--regular text-body-1 w-full hover:text-primary cursor-pointer flex justify-between;
  }
  &__breadcrumb {
    @apply text__body-3-md--regular text-primary cursor-pointer;
    > span {
      @apply flex items-center space-x-2;
    }
  }
}
</style>
